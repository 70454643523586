.auth-container {
  display: flex;
    justify-content: flex-end;
    flex-direction: initial;
    min-height: 100vh;
    background-size: cover;
    background-position: center;

    .login-content {
      position: relative;
      background-color: #fff;
      padding: 2rem;
      min-width: 300px;
      max-width: 550px;
      width: 100%;
      height: 100vh;
    }
    .logo {
      margin-top: 8rem;
      margin-bottom: 2rem;
      text-align: center;
      img {
        width: 100px;
      }
    }
    
    .login-form-submit {
      text-align: center;
      margin-bottom: 0.5rem;
    }

    .login-form-button {
      width: 100%;
      background-color: #02192d;
      border-color: #02192d;
    }
}