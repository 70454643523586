.line-clamp {
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;

  &.line-clamp-1 {
    -webkit-line-clamp: 1;
  }

  &.line-clamp-2 {
    -webkit-line-clamp: 2;
  }

  &.line-clamp-3 {
    -webkit-line-clamp: 3;
  }
}