@primary-color: #6F4E37;
@btn-font-weight: 500;
@border-radius-base: 6px;

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
  box-shadow: 0 0 0 30px transparent inset !important;
  transition: all 5000s ease-in-out 0s;
  transition-property: background-color, color;
}

.ant-input-affix-wrapper > input.ant-input {
  border-radius: 0;
}

.ant-btn-loading-icon {
  margin-bottom: 5px;
}